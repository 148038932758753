<template>
  <el-scrollbar :native="false">
    <div v-if="formAction" style="padding: 20px">
      <div class="titles">{{ __("Queue Name") }}</div>
      <div class="details">{{ contentFormInfo.queue_name }}</div>
      <div class="titles">{{ __("Account Plan") }}</div>
      <div class="details">
        {{
          contentFormInfo.ac_plan
            ? contentFormInfo.ac_plan.data[0].plan_type
            : ""
        }}
      </div>
      <div class="titles">{{ __("Call Center Number") }}</div>
      <div class="details">{{ contentFormInfo.queue_phone_number }}</div>
      <div class="titles">{{ __("Queue Status") }}</div>
      <div class="details">
        {{ contentFormInfo.queue_status === 1 ? "active" : "inactive" }}
      </div>
      <div class="titles">{{ __("API Key") }}</div>
      <div class="details" style="padding-bottom: 20px">
        <div class="api-key-item">
          <span class="api-key">{{ contentFormInfo.api_key }}</span>
          <span><copier :value="contentFormInfo.api_key"></copier></span>
        </div>
      </div>

      <div style="display: flex; margin-bottom: 40px">
        <el-dropdown @command="handleQueueAction" class="queueActionDropDown">
          <div>
            <el-tooltip
              effect="dark"
              placement="top"
              :content="__('You do not have permission to manage this item')"
              :disabled="this.canWrite()"
            >
              <span class="wrapper">
                <el-button
                  type="primary"
                  class="actionBtn"
                  :disabled="!this.canWrite()"
                >
                  {{ __("Actions")
                  }}<i class="el-icon-arrow-down el-icon--right"></i>
                </el-button>
              </span>
            </el-tooltip>
          </div>

          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
              v-for="(command, index) in queueCommands"
              :key="index"
              :command="command.command"
              :disabled="!canWrite()"
            >
              <div
                style="display: flex; justify-content: flex-start; align-items: center"
              >
                <img
                  :src="getIconSVG(command.icon)"
                  alt
                  height="18px"
                  width="18px"
                  style="margin-right: 10px"
                />
                <span>{{ command.label }}</span>
              </div>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>

      <el-dialog
        :visible.sync="openModal"
        v-if="openModal"
        :show-close="true"
        :before-close="notifyClosingOfModal"
        custom-class="editContentItemModal"
        destroy-on-close
        fullscreen
      >
        <component
          :queue="contentForm"
          v-bind:is="component"
          :id="id"
          @cancel="handleCancel"
        ></component>
      </el-dialog>
    </div>
  </el-scrollbar>
</template>

<script>
import PageHeader from "@/components/PageHeader";
import { mapActions, mapState } from "vuex";
import BaseContentInfoPanel from "@/views/build/content/mixins/BaseContentInfoPanel";
import BaseContent from "@/views/build/content/mixins/BaseContent";
import Copier from "@/components/Copier";
// import _ from "lodash";

export default {
  mixins: [BaseContent, BaseContentInfoPanel],
  name: "queueActions",
  components: {
    Copier,
    PageHeader
  },
  data() {
    return {
      queueCommands: [
        {
          command: "createOrEdit",
          icon: "campaign_actions/icon_pencil.svg",
          label: __("Edit")
        },
        {
          command: "apiInfo",
          icon: "campaign_actions/icon_info_sign.svg",
          label: __("Api Info")
        },
        {
          command: "delete",
          icon: "campaign_actions/icon_trash_new.svg",
          label: __("Delete")
        }
      ],
      rules: {
        queue_name: [
          { required: true, trigger: "blur", message: __("Name is required") }
        ],
        queue_phone_number: [
          {
            required: true,
            trigger: "blur",
            message: __("Phone number is required is required")
          },
          {
            min: 10,
            max: 15,
            message: __("Please provide a valid phone number."),
            trigger: "blur"
          }
        ]
      },
      isSubmitting: false,
      queueWaitersForCalculation: 0,
      originalWaitersAssigned: 0,
      originalPlanId: 0,
      planIds: {
        Standard: "Standard",
        Premium: "Premium"
      },
      selectedAction: "createOrEdit"
    };
  },

  computed: {
    ...mapState("app", {
      selectedServiceProviderId: state => state.selectedServiceProviderId,
      selectedAccountId: state => state.selectedAccountId
    }),

    ...mapState("qforme", {
      planTypes: state => state.planTypes,
      isLoading: state => state.loading
    }),

    component() {
      return this.importComponent(this.selectedAction);
    }
  },
  methods: {
    ...mapActions("qforme", {
      createQueue: "createQueue",
      updateQueue: "updateQueue",
      deleteContentMethod: "deleteQueue",
      undoDeleteContent: "undoDeleteQueue",
      getPlanTypesDetail: "getPlanTypesDetail"
    }),

    getIconSVG(name) {
      return require("@/assets/icons/" + name);
    },

    handleQueueAction(action) {
      if (action === "delete") {
        this.handleDelete(this.contentFormInfo);
      } else {
        if (["createOrEdit"].includes(action)) {
          this.$emit("edit-item");
        } else if (["apiInfo"].includes(action)) {
          this.$emit("extend-item");
        }
        this.selectedAction = action;
        this.handleOpenModal(true);
      }
    },

    importComponent(path) {
      return () => import("@/views/build/qforme/queues/pages/" + path);
    }
  },

  watch: {
    // contentForm: {
    //   deep: true,
    //   immediate: true,
    //   handler: function(queue) {
    //     console.log("contentForm", queue);
    //   }
    // },
    // contentFormInfo: {
    //   deep: true,
    //   immediate: true,
    //   handler: function(queue) {
    //     console.log("contentFormInfo", queue);
    //   }
    // },
    formAction: {
      immediate: true,
      handler: function(val) {
        if (val) {
          if (val === "edit" || val === "add") {
            this.selectedAction = "createOrEdit";
          }
        }
      }
    }
  }
};
</script>

<style scoped lang="scss">
$content-theme-color: var(--theme-color) !default;
$content-theme-hover-color: var(--theme-hover-color) !default;
$content-theme-row-hover-color: var(--theme-row-hover-color) !default;
$content-theme-outline-color: var(--theme-outline-color) !default;
$content-theme-color-white: #ffffff !default;
@import "~@/styles/content-edit-info.scss";

.submitBtn.is-disabled {
  color: white;
  //background-color: mix(white, $content-theme-color, 50%);
  border-color: $content-theme-outline-color !important;
}

::v-deep .el-input-number {
  .el-input-number__decrease:hover {
    color: $content-theme-hover-color;
  }
  .el-input-number__increase:hover {
    color: $content-theme-hover-color;
  }
  .el-input-number__decrease {
    background-color: #f5f5f5;
  }
  .el-input-number__increase {
    background-color: #f5f5f5;
  }
}

::v-deep .el-checkbox-group {
  .el-checkbox-button.is-focus .el-checkbox-button__inner {
    border-color: $content-theme-hover-color;
  }
  .el-checkbox-button .el-checkbox-button__inner:hover {
    color: $content-theme-hover-color;
  }
  .el-checkbox-button.is-checked .el-checkbox-button__inner:hover {
    color: $content-theme-color-white;
  }
}

::v-deep .el-checkbox {
  :hover {
    border-color: $content-theme-color;
  }
  .el-checkbox__input .el-checkbox__inner {
    border-color: $content-theme-color;
  }
  .el-checkbox__input + .el-checkbox__label {
    color: $content-theme-color;
  }
  .el-checkbox__input.is-checked .el-checkbox__inner {
    border-color: $content-theme-color;
    background: $content-theme-color;
  }
  .el-checkbox__input.is-checked + .el-checkbox__label {
    color: $content-theme-color;
  }

  .el-select-dropdown__item.selected {
    color: $content-theme-color;
  }
}

::v-deep .el-date-picker {
  :hover {
    color: $content-theme-hover-color;
  }
  .el-date-picker__inner {
    border-color: $content-theme-color;
    background: $content-theme-color;
  }
  .el-date-picker__label {
    color: $content-theme-color;
  }
}

::v-deep .el-select {
  .el-input.is-focus .el-input__inner {
    border-color: $content-theme-color;
  }

  .el-input__inner:hover {
    border-color: $content-theme-color;
  }

  .el-select-dropdown__item.selected {
    color: $content-theme-color;
  }

  .el-input__inner:focus {
    border-color: $content-theme-color;
  }
}

.api-key {
  word-break: break-word;
  padding-right: 5px;
}

.editContentItemModal {
  padding: 0;
  .el-dialog__header {
    padding: 0;
  }
  .el-dialog__body {
    padding: 0;
    overflow: hidden;
  }

  .el-dialog__footer {
    padding: 0;
  }
}

::v-deep .rounded-menu-icon-selected {
  fill: $content-theme-color;
  path {
    fill: $content-theme-color;
  }
}

.queueActionDropDown {
  color: white;
  background: $content-theme-color;
  border-radius: 6px;
  margin-top: 20px;

  ::v-deep button {
    background: $content-theme-color;
    border-color: transparent;
    height: 44px;
    &:disabled {
      color: white;
      //background-color: mix(white, $content-theme-color, 50%);
      border-color: $content-theme-outline-color !important;
    }
  }

  &:hover {
    ::v-deep button {
      background: $content-theme-hover-color;
    }
  }
}
</style>
