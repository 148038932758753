<template>
  <div style="width: 100%;" @click="handleClear">
    <div style="display: flex;flex-direction: row-reverse;">
      <el-tooltip
        effect="dark"
        placement="bottom"
        :content="__('You do not have permission to manage this item')"
        :disabled="this.canWrite()"
      >
        <span class="wrapper">
          <el-button
            class="createBtn"
            @click.stop="createContentItem"
            :disabled="!canWrite()"
            >{{ __("Create Queue") }}</el-button
          >
        </span>
      </el-tooltip>
    </div>
    <div style="width: 100%" v-loading="isLoading">
      <el-main style="margin-top: 136px;">
        <el-form class="form-container">
          <PaginationToolbar
            :sort-by-options="sortByOptions"
            :content-api="contentAPI"
            @success="$emit('replaceContent', $event)"
          >
          </PaginationToolbar>
        </el-form>
        <el-scrollbar :native="true">
          <div class="contentListContainer">
            <el-table
              ref="queuesTable"
              class="list-table"
              :data="queues"
              highlight-current-row
              @row-click="handleSingleClick"
              @row-dblclick="handleDoubleClick"
              :v-show="canRead()"
            >
              <el-table-column :label="__('Name')" prop="queue_name">
                <template slot-scope="scope">
                  <el-row
                    type="flex"
                    justify="flex-start"
                    style="align-items: center"
                  >
                    <img
                      :src="getRowIcon(scope.row)"
                      class="rounded-menu-icon"
                    />
                    <span style="margin-left: 20px;">{{
                      scope.row.queue_name
                    }}</span>
                  </el-row>
                </template>
              </el-table-column>
              <el-table-column
                :label="__('Call Center Number')"
                prop="queue_phone_number"
              />
              <el-table-column
                :label="__('Waiters Assigned')"
                prop="max_waiter"
              />
            </el-table>
          </div>
        </el-scrollbar>
      </el-main>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import PaginationToolbar from "@/components/PaginationToolbar";
import BaseContent from "@/views/build/content/mixins/BaseContent";

export default {
  name: "QForMeQueuesList",
  mixins: [BaseContent],
  components: {
    PaginationToolbar
  },
  props: {
    msg: {
      required: false,
      type: String,
      default: ""
    }
  },
  data() {
    return {
      sortByColumns: ["queue_name", "queue_id"],
      fetchedAccounts: false,
      search: "",
      filters: ["queue_name"],
      listLoading: false,
      listQuery: {
        page: 1,
        limit: 10
      }
    };
  },

  computed: {
    ...mapState("qforme", {
      queues: state => state.queues,
      isLoading: state => state.loading
    }),

    ...mapState("app", {
      selectedServiceProviderId: state => state.selectedServiceProviderId,
      selectedAccountId: state => state.selectedAccountId
    })
  },

  mounted() {
    if (this.msg) {
      this.$message({
        type: "success",
        message: this.msg
      });
    }
  },

  methods: {
    ...mapActions("qforme", {
      contentAPI: "getQueues",
      deleteContentMethod: "deleteQueue",
      undoDeleteContent: "undoDeleteQueue",
      setWaitersAvailable: "setWaitersAvailable"
    }),

    isPremium(rowItem) {
      if (rowItem.ac_plan.data[0].plan_type === "QforMe Premium") {
        return true;
      }
    },

    getRowIcon(rowItem) {
      if (this.isPremium(rowItem)) {
        return require("@/assets/icons/icon-premium-waiter.svg");
      } else {
        return require("@/assets/icons/icon-waiter.svg");
      }
    },

    handleSingleClick(row, column, event) {
      event.stopPropagation();
      this.handleSelect(null, row);
    },

    handleDoubleClick(row, column, event) {
      event.stopPropagation();
      this.handleEdit(null, row);
    },

    handleClear() {
      this.$refs.queuesTable && this.$refs.queuesTable.setCurrentRow();
      this.handleClearSelection();
    },

    handleDeleteItem(index, queue) {
      this.handleDelete(queue);
    }
  },

  watch: {
    selectedItemIndex: {
      immediate: true,
      handler: function(val) {
        if (val !== -1 && val !== null && val !== undefined) {
          this.$refs.queuesTable &&
            this.$refs.queuesTable.setCurrentRow(this.queues[val]);
        } else if (val === -1) {
          this.$refs.queuesTable && this.$refs.queuesTable.setCurrentRow();
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
$card-image-filter: invert(65%) sepia(43%) saturate(5014%) hue-rotate(170deg)
  brightness(104%) contrast(89%);
@import "~@/styles/content-buttons.scss";
@import "~@/styles/content-card.scss";
@import "~@/styles/pagination-toolbar.scss";

.wrapper {
  display: flex;
  margin-right: 5.4rem;
  margin-top: 4.6rem;
}
</style>
