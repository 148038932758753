<script>
import List from "./pages/list";
import CreateOrEdit from "./pages/queueActions";
import BaseIndex from "@/views/build/content/mixins/BaseIndex";
import { mapState } from "vuex";

export default {
  name: "QForMeQueuesIndex",
  mixins: [BaseIndex],
  components: {
    List,
    CreateOrEdit
  },

  data() {
    return {
      selectedContent: this.initContent()
    };
  },

  computed: {
    ...mapState("qforme", {
      contents: state => state.queues
    })
  },

  methods: {
    initContent() {
      return {
        queue_name: "",
        queue_phone_number: "",
        max_waiter: 0,
        queue_status: true,
        api_key: "",
        ac_plan_id: ""
      };
    }
  },

  mounted() {
    this.contentType = "QforMe Queue";
    this.primaryKey = "queue_id";
    this.scope = "ac";
  }
};
</script>

<style lang="scss" scoped></style>
